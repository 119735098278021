import { Button, PageHeader } from "antd";
import React, { useRef } from "react";
import { useNavigate } from "react-router";
import { notification } from "../../../components";
import { NewsForm, NewsFormImperative } from "../../../features";

const NewsCreate: React.FC = () => {
    const form = useRef<NewsFormImperative>();
    const navigate = useNavigate();

    const onActionSuccess = () => {
        navigate("/admin/news");
        notification.success("Berita", "Berhasil menambahkan berita.");
    };

    const onActionFail = (error: any) => {
        notification.error("Berita", error);
    };

    return (
        <PageHeader title="Tambah Berita" extra={[<Button onClick={() => form.current?.create()}>Tambah</Button>]}>
            <div style={{ marginTop: 40 }}>
                <NewsForm ref={form} action="create" onActionSuccess={onActionSuccess} onActionFail={onActionFail} newsId="-1" />
            </div>
        </PageHeader>
    );
};

export default NewsCreate;
