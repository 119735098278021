import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { merchantService } from "../../service";
import { MerchantDetail as IMerchantDetail } from "../../types";

const MerchantDetail: React.FC = () => {
    const location = useLocation();
    // eslint-disable-next-line
    const searchParams = new URLSearchParams(location.search);

    const [loading, setLoading] = useState<boolean>(true);
    const [merchant, setMerchant] = useState<IMerchantDetail>();

    const getMerchant = async (id: number) => {
        setLoading(true);
        try {
            const resp = await merchantService.getMerchant(id);
            setMerchant(resp.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (searchParams.get("id")) {
            getMerchant(Number(searchParams.get("id")));
        }
        // eslint-disable-next-line
    }, []);

    if (loading) return <span></span>;

    return (
        <div className="merchant-detail-wrapper">
            <div className="merchant-detail-banner-wrapper">
                {merchant?.merchant_details.banner_link ? <img src={`https://api.bina-umkm.unmul.ac.id/${merchant?.merchant_details.banner_link}`} alt={merchant?.merchant.logo} /> : null}
                <div className="merchant-detail-logo-wrapper">{merchant?.merchant.logo ? <img src={`https://api.bina-umkm.unmul.ac.id/${merchant?.merchant.logo}`} alt={merchant?.merchant.logo} /> : null}</div>
            </div>
            <div className="merchant-detail-body-wrapper">
                <div className="merchant-detail-title-wrapper">
                    <h2>{merchant?.merchant.name}</h2>
                    <span>Mulai dari Rp {merchant?.merchant.price_start_from || "10.000"}</span>
                </div>
                <div className="merchant-detail-content">
                    <div className="merchant-detail-content-left">
                        <div className="merchant-detail-content-description">
                            <h3>Deskripsi Toko</h3>
                            <p>{merchant?.merchant.description}</p>
                        </div>
                        <div className="merchant-action-wrapper">
                            {merchant?.merchant.merchant_type_id === 1 ? (
                                <a href={`https://tokopedia.com/${merchant.merchant_details.tokopedia_link_id}`} className="merchant-action-button tokopedia-btn">
                                    Kunjungi di Tokopedia
                                </a>
                            ) : null}
                            {merchant?.merchant.merchant_type_id === 2 ? (
                                <a href={`https://gofood.link/u/${merchant.merchant_details.gofood_link_id}`} className="merchant-action-button gofood-btn">
                                    Kunjungi di GoFood
                                </a>
                            ) : null}
                            <a href={`${merchant?.merchant_details.google_business_link_id}`} className="merchant-action-button googlebusiness-btn">
                                Kunjungi Toko Kami Langsung
                            </a>
                            <a href={`https://wa.me/${merchant?.merchant.whatsapp_number}`} className="merchant-action-button whatsapp-btn">
                                Hubungi melalui WhatsApp
                            </a>
                        </div>
                    </div>
                    <div className="merchant-detail-content-right">
                        <div className="merchant-detail-content-description">
                            <h3>Kategori</h3>
                            <div className="merchant-detail-conten-description-categories">
                                {merchant?.merchant_selected_categories_detail.map((cat, key) => {
                                    return <span key={key}>{cat.merchant_category.name}</span>;
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: 50, border: "1px solid rgba(0, 0, 0, 0.1)" }} />

                <div className="merchant-galleries-wrapper">
                    <h3>Galeri Produk</h3>
                    <div className="merchant-galleries">
                        {merchant?.merchant_galleries.map((gal, key) => {
                            return (
                                <div className="merchant-gallery">
                                    <img src={`https://api.bina-umkm.unmul.ac.id/${gal.image_url}`} alt={gal.product_name} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MerchantDetail;
