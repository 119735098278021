import { Badge, Button, Modal, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { notification } from "../../components";
import useCurrentUser from "../../hooks/useCurrentUser";
import { merchantService } from "../../service";
import { Merchant, UserRole } from "../../types";

interface MerchantTableProps {
    status: "waiting_approval" | "approved";
}

export const MerchantTable: React.FC<MerchantTableProps> = (props) => {
    const navigate = useNavigate();
    const { role } = useCurrentUser();

    const [merchants, setMerchants] = useState<Merchant[]>([]);

    const getMerchants = async () => {
        try {
            const resp = await merchantService.getMerchants({ filters: { merchant_status: props.status } });
            console.log("resp", resp);
            setMerchants(resp.data);
        } catch (error) {
            console.log("error MerchantTable.getMerchants", error);
        }
    };

    const approveMerchant = (id: number) =>
        Modal.confirm({
            content: "Setujui pedagang ini",
            okText: "Setujui",
            cancelText: "Batal",
            onOk: () =>
                merchantService.approveMerchant(id).then(() => {
                    notification.success("Toko/Warung", "Berhasil menyetujui pedagang");
                    getMerchants();
                }),
        });

    const disapproveMerchant = (id: number) =>
        Modal.confirm({
            content: "Batal setujui pedagang ini",
            okText: "Batal Setujui",
            cancelText: "Batal",
            onOk: () =>
                merchantService.disapproveMerchant(id).then(() => {
                    notification.success("Toko/Warung", "Berhasil batal menyetujui pedagang");
                    getMerchants();
                }),
        });

    useEffect(() => {
        getMerchants();
        // eslint-disable-next-line
    }, []);

    const columns: ColumnsType<Merchant> = [
        {
            title: "Nama",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Deskripsi",
            dataIndex: "description",
            key: "description",
            width: 300,
        },
        {
            title: "Penanggung Jawab",
            dataIndex: "pic_name",
            key: "pic_name",
        },
        {
            title: "Status",
            dataIndex: "approved_at",
            key: "approved_at",
            render: (_, data) => {
                if (data.approved_at) {
                    return <Badge status="success" text="Online" />;
                } else {
                    return <Badge status="processing" text="Menunggu Persetujuan" />;
                }
            },
        },
        {
            title: "Tindakan",
            dataIndex: "id",
            key: "action",
            width: "100px",
            render: (_, data) => (
                <Space>
                    {role === UserRole.ADMIN ? (
                        <Button onClick={() => approveMerchant(data.id)} disabled={data.approved_at} type="link">
                            Setujui
                        </Button>
                    ) : null}
                    {role === UserRole.ADMIN ? (
                        <Button onClick={() => disapproveMerchant(data.id)} disabled={!data.approved_at} type="link">
                            Batal Setuju
                        </Button>
                    ) : null}
                    {/* <Button onClick={() => navigate(`/admin/merchant/view/${data.id}`)} disabled type="link">
                        Lihat
                    </Button> */}
                    <Button onClick={() => navigate(`/admin/merchant/edit/${data.id}`)} type="link">
                        Ubah
                    </Button>
                    {/* <Button onClick={() => navigate(`/admin/merchant/edit/${data.id}`)} disabled type="link" danger>
                        Hapus
                    </Button> */}
                </Space>
            ),
        },
    ];

    return <Table dataSource={merchants} columns={columns} />;
};
