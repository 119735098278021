import "./App.css";
import { Route, Routes } from "react-router";

import AdminLayout from "./layouts/admin/AdminLayout";
import MainLayout from "./layouts/public/MainLayout";

import Transition from "./screens/Transition";

import Home from "./screens/public/Home";
import PublicMerchantDetail from "./screens/public/MerchantDetail";
import PublicMerchantList from "./screens/public/MerchantList";
import PublicNewsDetail from "./screens/public/NewsDetail";
import PublicNewsList from "./screens/public/NewsList";

import UserList from "./screens/admin/users/UserList";
import UserCreate from "./screens/admin/users/UserCreate";
import UserEdit from "./screens/admin/users/UserEdit";

import MerchantListApproved from "./screens/admin/merchants/MerchantListApproved";
import MerchantListWaitingApproval from "./screens/admin/merchants/MerchantListWaitingApproval";
import MerchantCreate from "./screens/admin/merchants/MerchantCreate";
import MerchantEdit from "./screens/admin/merchants/MerchantEdit";

import NewsList from "./screens/admin/news/NewsList";
import NewsCreate from "./screens/admin/news/NewsCreate";
import NewsEdit from "./screens/admin/news/NewsEdit";
import { CurrentUserProvider } from "./context/CurrentUser";

const publicScreens = [
    {
        path: "",
        element: Home,
    },
    {
        path: "/pedagang/:merchantType",
        element: PublicMerchantList,
    },
    {
        path: "/pedagang/:merchantType/:merchantName",
        element: PublicMerchantDetail,
    },
    {
        path: "/berita",
        element: PublicNewsList,
    },
    {
        path: "/berita/:newsTitle",
        element: PublicNewsDetail,
    },
    {
        path: "/tentang",
        element: Home,
    },
];

const adminScreens = [
    {
        path: "",
        element: Transition,
    },
    {
        path: "*",
        element: Transition,
    },
    {
        path: "users",
        element: UserList,
    },
    {
        path: "user/add",
        element: UserCreate,
    },
    {
        path: "user/edit/:userId",
        element: UserEdit,
    },
    {
        path: "merchants",
        element: MerchantListApproved,
    },
    {
        path: "merchants/waiting_approval",
        element: MerchantListWaitingApproval,
    },
    {
        path: "merchant/add",
        element: MerchantCreate,
    },
    {
        path: "merchant/edit/:merchantId",
        element: MerchantEdit,
    },
    {
        path: "news",
        element: NewsList,
    },
    {
        path: "news/add",
        element: NewsCreate,
    },
    {
        path: "news/edit/:newsId",
        element: NewsEdit,
    },
];

const App = () => {

    return (
        <CurrentUserProvider value={{ currentUser: {}, setCurrentUser: () => null }}>
            <Routes>
                {publicScreens.map((screen, index) => (
                    <Route
                        key={index}
                        path={`/${screen.path}`}
                        element={
                            <MainLayout>
                                <screen.element />
                            </MainLayout>
                        }
                    />
                ))}
                {adminScreens.map((screen, index) => (
                    <Route
                        key={index}
                        path={`/admin/${screen.path}`}
                        element={
                            <AdminLayout>
                                <screen.element />
                            </AdminLayout>
                        }
                    />
                ))}
            </Routes>
        </CurrentUserProvider>
    );
};

export default App;
