import { Button, PageHeader } from "antd";
import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { notification } from "../../../components";
import { NewsForm, NewsFormImperative } from "../../../features";

interface NewsEditParams {
    newsId?: string;
}

const NewsEdit: React.FC = () => {
    const form = useRef<NewsFormImperative>();
    const navigate = useNavigate();
    const params = useParams() as NewsEditParams;

    const onActionSuccess = () => {
        navigate("/admin/news");
        notification.success("Berita", "Berhasil mengubah berita.");
    };

    const onActionFail = (error: any) => {
        notification.error("Berita", error);
    };

    return (
        <PageHeader
            title="Ubah Berita"
            extra={[
                <Button onClick={() => form.current?.update()} type="primary">
                    Perbaharui
                </Button>,
            ]}
        >
            <div style={{ marginTop: 40 }}>
                <NewsForm ref={form} action="edit" onActionSuccess={onActionSuccess} onActionFail={onActionFail} newsId={params.newsId ? params.newsId : "-1"} />
            </div>
        </PageHeader>
    );
};

export default NewsEdit;
