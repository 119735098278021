import axios from "axios";

const primary = axios.create({
    baseURL: "https://api.bina-umkm.unmul.ac.id",
    // baseURL: "http://127.0.0.1:8000",
});

primary.interceptors.request.use(
    async (config) => {
        try {
            const token = localStorage.getItem("token");
            if (token !== null) {
                const authorization = {
                    Authorization: token,
                };
                config.headers = Object.assign(config.headers, authorization);
            }
            return config;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    (err) => {
        console.log("err", err);
        return Promise.reject(err);
    }
);

primary.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response.data;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // console.log(error.response.data)
        // console.log(error.response.status)
        // console.log(error.response.headers)
        if (error.response.data.message === "Session Expired") {
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export default primary;
