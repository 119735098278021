import { Button, PageHeader } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { UserTable } from "../../../features";

const UserList: React.FC = () => {
    const navigate = useNavigate();

    return (
        <PageHeader
            title="Daftar Pengguna"
            extra={
                <Button onClick={() => navigate("/admin/user/add")} type="primary">
                    Tambah Pengguna
                </Button>
            }
        >
            <UserTable />
        </PageHeader>
    );
};

export default UserList;
