import { Button, Divider, Input, Select, SelectProps } from "antd";
import { useEffect, useState } from "react";
import { merchantService } from "../../service";
import { PlusOutlined } from "@ant-design/icons";
import { notification } from "../../components";

interface SelectMerchantCategoryProps extends SelectProps<any> {
    merchantTypeId?: number;
}

export const SelectMerchantCategory = (props: SelectMerchantCategoryProps) => {
    const [categories, setCategories] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [newCategory, setNewCategory] = useState<string>("");
    const [sending, setSending] = useState<boolean>(false);

    const selectPropsOrigin = props;

    const getCategories = async (typeId: number) => {
        setLoading(true);
        try {
            const resp = await merchantService.getCategories(typeId);
            setCategories(resp.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("error SelectMerchantCategory.getCategories", error);
        }
    };

    const addNewCategory = (category: string) => {
        setSending(true);
        if (props.merchantTypeId) {
            merchantService
                .addCategory(category, props.merchantTypeId)
                .then((resp) => {
                    if (props.merchantTypeId) {
                        getCategories(props.merchantTypeId);
                        setNewCategory("");
                        setSending(false);
                        notification.success("Kategori", "Kategori berhasil ditambahkan.");
                    }
                })
                .catch((error) => {
                    setSending(false);
                    notification.error("Kategori", error);
                });
        }
    };

    useEffect(() => {
        if (props.merchantTypeId) {
            getCategories(props.merchantTypeId);
        }
    }, [props.merchantTypeId]);

    return (
        <Select
            {...selectPropsOrigin}
            mode="multiple"
            disabled={props.disabled || loading}
            loading={props.loading || loading}
            showSearch
            filterOption={(input, option) => {
                const opt: string = String(option?.children);
                const val: string = input;
                return opt.includes(val);
            }}
            dropdownRender={(menu) => (
                <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div style={{ display: "flex", flexWrap: "nowrap", padding: 8, columnGap: 5 }}>
                        <Input disabled={!props.merchantTypeId || sending} value={newCategory} placeholder="Masukkan kategori baru" onChange={(e) => setNewCategory(e.target.value)} style={{ flex: "auto" }} />
                        <Button disabled={!props.merchantTypeId || sending} onClick={() => addNewCategory(newCategory)} icon={<PlusOutlined />} type="link">
                            Tambah Kategori
                        </Button>
                    </div>
                </div>
            )}
        >
            {categories.map((category, key) => {
                return (
                    <Select.Option key={key} value={category.id}>
                        {category.name}
                    </Select.Option>
                );
            })}
        </Select>
    );
};
