import { APIGetRequest, APIResponse, GetMerchantFilters, Merchant, MerchantCreateRequest, MerchantDetail, MerchantUpdateRequest } from "../types";
import api from "./api";

export const getMerchants = (request?: APIGetRequest<Partial<GetMerchantFilters>>): Promise<APIResponse<Merchant[]>> => api.post("/api/merchants", request);

export const getMerchant = (id: number): Promise<APIResponse<MerchantDetail>> => api.get(`/api/merchant?id=${id}`);

export const createMerchant = (data: MerchantCreateRequest) => api.post("/api/merchant", data);

export const updateMerchant = (data: MerchantUpdateRequest) => api.put("/api/merchant", data);

export const approveMerchant = (id: number) => api.put("/api/merchant/approve", { id });

export const disapproveMerchant = (id: number) => api.put("/api/merchant/disapprove", { id });

export const getCategories = (typeId: number): Promise<APIResponse<any>> => api.get(`/api/merchant-categories?merchant_type_id=${typeId}`);

export const addCategory = (name: string, merchant_type_id: number) => api.post("/api/merchant-category", { name, merchant_type_id });
