import { Button, PageHeader } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { MerchantTable } from "../../../features";

const MerchantListWaitingApproval: React.FC = () => {
    const navigate = useNavigate();

    return (
        <PageHeader
            title="Daftar Toko/Warung - Menunggu Persetujuan"
            extra={
                <Button onClick={() => navigate("/admin/merchant/add")} type="primary">
                    Tambah Pedagang
                </Button>
            }
        >
            <MerchantTable status="waiting_approval" />
        </PageHeader>
    );
};

export default MerchantListWaitingApproval;
