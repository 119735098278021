import { forwardRef, useImperativeHandle, useState } from "react";
import { Form, Input, Collapse } from "antd";
import { identityService } from "../../service";
import { IdentityCreateRequest } from "../../types";

export interface IdentityFormImperativeHandle {
    create: () => void;
}

interface UserFormProps {
    userId: number;
    action: "create" | "update";
    onActionSuccess: () => void;
    onActionFail: (error: any) => void;
}

const { Panel } = Collapse;

export const IdentityForm = forwardRef((props: UserFormProps, ref) => {
    const [identityForm] = Form.useForm<IdentityCreateRequest>();

    const [sending, setSending] = useState<boolean>(false);
    const [loading] = useState<boolean>(false);

    useImperativeHandle(
        ref,
        (): IdentityFormImperativeHandle => ({
            create() {
                identityForm.submit();
            },
        })
    );

    const create = async (values: IdentityCreateRequest) => {
        setSending(true);
        try {
            if (props.action === "create") {
                await identityService.createIdentity(values);
            }

            setSending(false);
            props.onActionSuccess();
        } catch (error) {
            setSending(false);
            props.onActionFail(error);
        }
    };

    return (
        <Form form={identityForm} layout="vertical" name="identityForm" onFinish={create}>
            <Form.Item label="Nama Lengkap" name="full_name" rules={[{ required: true, message: "Nama lengkap wajib diisi" }]}>
                <Input disabled={sending || loading} />
            </Form.Item>

            <Form.Item label="Nomor KTP" name="id_number" rules={[{ required: true, message: "Nomor KTP wajib diisi" }]}>
                <Input disabled={sending || loading} />
            </Form.Item>

            <Collapse ghost>
                <Panel header="Informasi Tambahan" key="1" style={{ padding: 0 }}>
                    <Form.Item label="Jenis Kelamin" name="gender">
                        <Input disabled={sending || loading} />
                    </Form.Item>

                    <Form.Item label="Alamat" name="address">
                        <Input disabled={sending || loading} />
                    </Form.Item>

                    <Form.Item label="Provinsi" name="province">
                        <Input disabled={sending || loading} />
                    </Form.Item>

                    <Form.Item label="Kota" name="city">
                        <Input disabled={sending || loading} />
                    </Form.Item>

                    <Form.Item label="Kecamatan" name="district">
                        <Input disabled={sending || loading} />
                    </Form.Item>

                    <Form.Item label="Kelurahan" name="subdistrict">
                        <Input disabled={sending || loading} />
                    </Form.Item>

                    <Form.Item label="Agama" name="religion">
                        <Input disabled={sending || loading} />
                    </Form.Item>

                    <Form.Item label="Status Pernikahan" name="marital_status">
                        <Input disabled={sending || loading} />
                    </Form.Item>

                    <Form.Item label="Profesi" name="profession">
                        <Input disabled={sending || loading} />
                    </Form.Item>

                    <Form.Item label="Email" name="email">
                        <Input disabled={sending || loading} />
                    </Form.Item>

                    <Form.Item label="Nomor Telepon" name="mobile_number">
                        <Input disabled={sending || loading} />
                    </Form.Item>
                </Panel>
            </Collapse>

            {/* 
                <Form.Item wrapperCol={{ offset: 3, span: 8 }}>
                    <Button disabled={sending || loading} type="primary" htmlType="submit">
                        {props.action === "create" ? "Tambah" : null}
                        {props.action === "update" ? "Perbaharui" : null}
                    </Button>
                </Form.Item> 
            */}
        </Form>
    );
});
