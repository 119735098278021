import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { merchantService, newsService } from "../../service";
import { Merchant, NewsList } from "../../types";

interface MerchantHightlightProps {
    type: "souvenir" | "food";
}

export const getInitials = (fullName: string): string => {
    const allNames = fullName.trim().split(" ");
    const initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
            acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
    }, "");
    return initials;
};

const MerchantHightlight: React.FC<MerchantHightlightProps> = (props) => {
    const [merchants, setMerchants] = useState<Merchant[]>([]);
    let thumbnailImg = "/images/souvenir-thumbnail.jpg";
    let thumbnailImgAlt = "Souvenir dan Kerajinan";
    let title = "Souvenir & Kerajinan";
    let titleDescription = "Temukan berbagai oleh-oleh dan pernak-pernik hasil dari masyarakat lokal Samarinda";
    let type = props.type === "food" ? "makanan" : "souvenir";

    if (props.type === "food") {
        thumbnailImg = "/images/food-thumbnail.jpg";
        thumbnailImgAlt = "Makanan";
        title = "Masakan Lokal";
        titleDescription = "Berbagai makanan dari camilan hingga makanan khas daerah buatan masyarakat lokal yang pastinya lezat.";
    }

    const [loading, setLoading] = useState<boolean>(true);

    const getMerchant = async () => {
        setLoading(true);
        try {
            const resp = await merchantService.getMerchants({
                limit: 8,
                filters: {
                    merchant_type_id: type === "souvenir" ? 1 : 2,
                    merchant_status: "approved",
                },
            });
            setMerchants(resp.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        getMerchant();
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            {loading ? (
                <Skeleton />
            ) : (
                <div className="merchant-hightlight-wrapper">
                    <div className="merchant-hightlight-header-wrapper">
                        <div className="merchant-hightlight-thumbnail">
                            <img src={thumbnailImg} alt={thumbnailImgAlt} />
                        </div>
                        <div className="merchant-hightlight-header">
                            <h2>{title}</h2>
                            <p>{titleDescription}</p>
                        </div>
                    </div>
                    <div className="merchant-hightlight-body-wrapper">
                        <div className="merchant-hightlight-items">
                            {merchants.map((merchant, index) => {
                                if (index < 8) {
                                    return (
                                        <div className="merchant-hightlight-item">
                                            <Link to={`/pedagang/${type}/${merchant.name}?uuid=${merchant.uuid}-${merchant.uuid}&&id=${merchant.id}`}>
                                                <div className="merchant-hightlight-item-thumbnail">{merchant.logo ? <img height="160" width="160" src={`http://api.bina-umkm.unmul.ac.id/${merchant.logo}`} alt={merchant.name} /> : <span style={{ fontSize: 32, opacity: 0.7, fontWeight: "bold" }}>{getInitials(merchant.name)}</span>}</div>
                                                <div className="merchant-hightlight-item-content">
                                                    <span className="merchant-hightlight-item-content-name">{merchant.name}</span>
                                                    <span className="merchant-hightlight-item-content-price">Mulai dari {merchant.price_start_from || "0"}</span>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

const Home: React.FC = () => {
    const [news, setNews] = useState<NewsList[]>([]);

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        newsService
            .getNews({ limit: 5 })
            .then((data) => {
                setNews(data.data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, []);

    return (
        <React.Fragment>
            <section id="souvenir">
                <MerchantHightlight type="souvenir" />
            </section>

            <section id="food">
                <MerchantHightlight type="food" />
            </section>

            <section id="news">
                {loading ? (
                    <Skeleton />
                ) : (
                    <div className="merchant-hightlight-wrapper">
                        <div className="merchant-hightlight-header-wrapper">
                            <div className="merchant-hightlight-thumbnail">
                                <img src="/images/news-thumbnail.png" alt="Berita" />
                            </div>
                            <div className="merchant-hightlight-header">
                                <h2 style={{ width: "50%" }}>Kanal Berita</h2>
                                <p>Lihat berita terkini mengenai perkembangan dan produk UMKM di Samarinda.</p>
                            </div>
                        </div>
                        <div className="news-hightlight-items-wrapper">
                            {news.map((n, index) => {
                                if (index < 5) {
                                    return (
                                        <Link to={`/berita/${n.title}?title=${n.title}-${n.title}&&id=${n.id}`}>
                                            <div className="news-hightlight-item-wrapper">
                                                <div className="news-hightlight-item-thumbnail">{n.main_picture ? <img height="100" width="100" src={`http://api.bina-umkm.unmul.ac.id/${n.main_picture}`} alt={n.title} /> : <span style={{ fontSize: 32, opacity: 0.7, fontWeight: "bold" }}>{getInitials(n.title)}</span>}</div>
                                                <div className="news-hightlight-item-body">
                                                    <span className="news-hightlight-item-created-at">{n.created_at}</span>
                                                    <span className="news-hightlight-item-title">{n.title}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                )}
            </section>
        </React.Fragment>
    );
};

export default Home;
