import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { newsService } from "../../service";
import { NewsList as NewsListI } from "../../types";

const NewsList: React.FC = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<NewsListI[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [limit, setLimit] = useState<number>(5);

    useEffect(() => {
        newsService
            .getNews({ limit: limit })
            .then((resp) => {
                setData(resp.data);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, [limit]);

    return (
        <React.Fragment>
            {loading ? (
                <Skeleton />
            ) : (
                <div className="news-list-wrapper">
                    <div className="news-list-heading-wrapper">
                        <img src="/images/news-heading-icon.png" alt="Berita" />
                        <h2>Kanal Berita</h2>
                    </div>

                    <div className="news-list-items">
                        {data.map((d, k) => {
                            return (
                                <div key={k} onClick={() => navigate(`/berita/${d.title}?title=${d.title}-${d.title}&&id=${d.id}`)} className="news-list-item">
                                    <div className="news-list-item-left">{d.main_picture ? <img src={`https://api.bina-umkm.unmul.ac.id/${d.main_picture}`} alt={d.title} /> : null}</div>
                                    <div className="news-list-item-right">
                                        <span className="news-list-item-created-at">{d.created_at}</span>
                                        <span className="news-list-item-title">{d.title}</span>
                                        <div className="news-list-item-tags">
                                            {d.selected_news_tag.map((tag, key) => {
                                                return <span className="news-list-item-tag">{tag.news_tag.name}</span>;
                                            })}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <div className="list-load-more-wrapper" onClick={() => setLimit(limit + 5)}>
                        <span>Muat lebih banyak</span>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default NewsList;
