import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, notification } from "antd";
import { userService } from "../../service";
import { UserCreateRequest } from "../../types";
import { SelectIdentity } from "../../components";

interface UserFormProps {
    userId: number;
    action: "create" | "update";
}

const randomPassword = (length: number): string => {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const UserForm: React.FC<UserFormProps> = (props) => {
    const [userForm] = Form.useForm<UserCreateRequest>();

    const [sending, setSending] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const getUser = async (id: number) => {
        setLoading(true);
        try {
            const resp = await userService.getUser(id);
            userForm.setFieldsValue({
                username: resp.data.username,
                role: resp.data.role,
                identity_id: resp.data.identity_id,
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error({ message: "Info User", description: String(error) });
        }
    };

    const create = async (values: UserCreateRequest) => {
        setSending(true);
        try {
            let desc = "Berhasil menambahkan user.";
            if (props.action === "create") {
                await userService.createUser(values);
            }

            if (props.action === "update" && props.userId > 0) {
                await userService.updateUser({ id: props.userId, ...values });
                desc = "Berhasil mengubah user.";
            }

            setSending(false);
            notification.success({ message: "User", description: desc });
        } catch (error) {
            setSending(false);
            notification.error({ message: "User", description: String(error) });
        }
    };

    useEffect(() => {
        if (props.userId > 0) {
            getUser(props.userId);
        }
        // eslint-disable-next-line
    }, [props.userId]);

    return (
        <Form form={userForm} name="userForm" labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} initialValues={{ password: randomPassword(6) }} onFinish={create}>
            <Form.Item label="Username" name="username" rules={[{ required: true, message: "Username wajib diisi" }]}>
                <Input disabled={sending || loading} />
            </Form.Item>

            <Form.Item label="Role" name="role" rules={[{ required: true, message: "Role wajib diisi" }]}>
                <Select disabled={sending || loading}>
                    <Select.Option value="admin">Admin</Select.Option>
                    <Select.Option value="editor">Editor</Select.Option>
                    <Select.Option value="data_entry">Data Entry</Select.Option>
                    <Select.Option value="guest">Guest</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item label="Identitas" name="identity_id" rules={[{ required: true, message: "Identitas wajib diisi" }]}>
                <SelectIdentity disabled={sending || loading} />
            </Form.Item>

            {props.action === "create" ? (
                <Form.Item label="Password" name="password">
                    <Input disabled={sending || loading} />
                </Form.Item>
            ) : null}

            <Form.Item wrapperCol={{ offset: 3, span: 8 }}>
                <Button disabled={sending || loading} type="primary" htmlType="submit">
                    {props.action === "create" ? "Tambah" : null}
                    {props.action === "update" ? "Perbaharui" : null}
                </Button>
            </Form.Item>
        </Form>
    );
};
