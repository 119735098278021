import { APIResponse, Identity, IdentityCreateRequest, IdentityUpdateRequest } from "../types";
import api from "./api";

export const getIdentities = (): Promise<APIResponse<Identity[]>> => api.get("/api/identities");

export const getIdentity = (id: number): Promise<APIResponse<Identity>> => api.get(`/api/user?id=${id}`);

export const createIdentity = (data: IdentityCreateRequest) => api.post("/api/identity", data);

export const updateIdentity = (data: IdentityUpdateRequest) => api.put("/api/identity", data);
