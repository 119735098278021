import React, { createContext, useState } from "react";

import { User } from "../types";

interface ICurrentUserContextProvider {
    value: ICurrentUserContext
}

interface ICurrentUserContext {
    currentUser: Partial<User>
    setCurrentUser: (user: User) => void
}

const defaultCurrentUser: ICurrentUserContext = {
    currentUser: {},
    setCurrentUser: (user) => null
};

const CurrentUserCtx = createContext(defaultCurrentUser);

export const CurrentUserProvider: React.FC<ICurrentUserContextProvider> = props => {
    const [user, setUser] = useState<Partial<User>>(props.value.currentUser)

    const valueReturn = { 
        currentUser: user, 
        setCurrentUser: (newUser: User) => setUser(newUser)
    }

    return <CurrentUserCtx.Provider value={valueReturn}>{props.children}</CurrentUserCtx.Provider>
}

export const CurrentUserConsumer = CurrentUserCtx.Consumer;

export default CurrentUserCtx;
