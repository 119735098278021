export enum UserRole {
    ADMIN = "admin",
    EDITOR = "editor",
    DATA_ENTRY = "data-entry",
    GUEsT = "guest",
}

export interface User {
    id: number;
    uuid: string;
    identity_id: number;
    username: string;
    role: UserRole;
    created_at: Date;
    created_by?: any;
    updated_at: Date;
    updated_by?: any;
    deleted_at?: any;
    deleted_by?: any;
}

export interface UserCreateRequest {
    username: string;
    role: string;
    password: string;
    identity_id: number;
}

export interface UserUpdateRequest extends UserCreateRequest {
    id: number;
}

export interface UserLoginResp {
    token: string;
    expired: Date;
}
