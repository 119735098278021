import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Typography, Upload, message } from "antd";
import { merchantService } from "../../service";
import { APIResponse, FileUploadResp, MerchantCreateRequest, MerchantUpdateRequest } from "../../types";
import { SelectIdentity, SelectMerchantCategory } from "../../components";
import { UploadOutlined } from "@ant-design/icons";
import { UploadChangeParam, RcFile } from "antd/lib/upload";
import { HttpRequestHeader, UploadFile } from "antd/lib/upload/interface";
import useCurrentUser from "../../hooks/useCurrentUser";

interface UserFormProps {
    merchantId: number;
    action: "create" | "update";
    onActionSuccess: () => void;
    onActionFail: (error: any) => void;
}

const { Title } = Typography;

type FileListType = UploadFile<APIResponse<FileUploadResp>>;

export const MerchantForm: React.FC<UserFormProps> = (props) => {
    const [merchantForm] = Form.useForm<MerchantCreateRequest>();
    const { token } = useCurrentUser()

    const uploadHeaders: HttpRequestHeader = {
        Authorization: token || ''
    }

    const [defaultLogoFileList, setDefaultLogoFileList] = useState<FileListType>();
    const [defaultBannerFileList, setDefaultBannerFileList] = useState<FileListType>();
    const [defaultGalleriesFileList, setDefaultGalleriesFileList] = useState<FileListType[]>([]);
    const [selectedType, setSelectedType] = useState<number>();

    const [sending, setSending] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const getMerchant = async (id: number) => {
        setLoading(true);
        try {
            const resp = await merchantService.getMerchant(id);

            setSelectedType(resp.data.merchant.merchant_type_id);

            const galleries = resp.data.merchant_galleries;
            const defaultGalleries: FileListType[] = [];
            for (let index = 0; index < galleries.length; index++) {
                const gallery = galleries[index];
                defaultGalleries.push({
                    uid: index.toString(),
                    name: "Gallery" + index.toString(),
                    status: "done",
                    response: {
                        data: {
                            file: gallery.image_url,
                        },
                        message: "OK",
                    },
                    url: `https://api.bina-umkm.unmul.ac.id/${gallery.image_url}`,
                });
            }
            setDefaultGalleriesFileList(defaultGalleries);

            if (resp.data.merchant.logo) {
                const defaultLogo: FileListType = {
                    uid: resp.data.merchant.id.toString(),
                    name: "Logo",
                    status: "done",
                    response: {
                        data: {
                            file: resp.data.merchant.logo,
                        },
                        message: "OK",
                    },
                    url: `https://api.bina-umkm.unmul.ac.id/${resp.data.merchant.logo}`,
                };
                setDefaultLogoFileList(defaultLogo);
            }

            if (resp.data.merchant_details.banner_link) {
                const defaultBanner: FileListType = {
                    uid: resp.data.merchant.id.toString(),
                    name: "Banner",
                    status: "done",
                    response: {
                        data: {
                            file: resp.data.merchant_details.banner_link,
                        },
                        message: "OK",
                    },
                    url: `https://api.bina-umkm.unmul.ac.id/${resp.data.merchant_details.banner_link}`,
                };
                setDefaultBannerFileList(defaultBanner);
            }

            setTimeout(() => {
                merchantForm.setFieldsValue(resp.data);
                setLoading(false);
            }, 300);
        } catch (error) {
            setLoading(false);
        }
    };

    const create = async (values: MerchantCreateRequest) => {
        setSending(true);
        try {
            if (props.action === "create") {
                await merchantService.createMerchant({
                    merchant: values.merchant,
                    merchant_details: values.merchant_details || {},
                    merchant_galleries: values.merchant_galleries || [],
                    merchant_selected_categories: values.merchant_selected_categories,
                });
            }

            if (props.action === "update") {
                const payload: MerchantUpdateRequest = {
                    merchant: {
                        id: props.merchantId,
                        ...values.merchant,
                    },
                    merchant_details: values.merchant_details || {},
                    merchant_galleries: values.merchant_galleries || [],
                    merchant_selected_categories: values.merchant_selected_categories,
                };
                await merchantService.updateMerchant(payload);
            }

            setSending(false);
            props.onActionSuccess();
        } catch (error) {
            setSending(false);
            props.onActionFail(error);
        }
    };

    const getValueFromEventGalery = (uploadRes: UploadChangeParam<UploadFile<APIResponse<FileUploadResp>>>) => {
        const files = uploadRes.fileList;
        const usedFile: MerchantCreateRequest["merchant_galleries"] = [];
        for (let index = 0; index < files.length; index++) {
            const file: UploadFile<APIResponse<FileUploadResp>> = files[index];
            if (file.response?.data.file) {
                usedFile.push({
                    image_url: file.response?.data.file || "",
                    product_name: "",
                    product_price: "",
                });
            }
        }

        return usedFile;
    };

    const getFileURLValueFromEvent = (uploadRes: UploadChangeParam<UploadFile<APIResponse<FileUploadResp>>>) => {
        return uploadRes.file.response?.data.file;
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("Hanya dapat mengunggah file dengan extensi JPG atau PNG.");
            return Upload.LIST_IGNORE;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Hanya dapat mengunggah file dibawah 2MB.");
            return Upload.LIST_IGNORE;
        }
    };

    useEffect(() => {
        if (props.merchantId > 0) {
            getMerchant(props.merchantId);
        }
        // eslint-disable-next-line
    }, [props.merchantId]);

    return (
        <Form
            form={merchantForm}
            name="merchantForm"
            onValuesChange={(v) => {
                if (v.merchant?.merchant_type_id) {
                    merchantForm.resetFields(["merchant_selected_categories"]);
                    setSelectedType(v.merchant.merchant_type_id);
                }
            }}
            onFinish={create}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 12 }}
        >
            <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
                <Title style={{ margin: 0 }} level={4}>
                    Toko
                </Title>
            </Form.Item>

            <Form.Item label="Nama Toko/Warung" name={["merchant", "name"]} rules={[{ required: true, message: "Nama Toko/Warung wajib diisi" }]}>
                <Input disabled={sending || loading} />
            </Form.Item>

            <Form.Item label="Deskripsi" name={["merchant", "description"]} rules={[{ required: true, message: "Deskripsi wajib diisi" }]}>
                <Input disabled={sending || loading} />
            </Form.Item>

            <Form.Item label="Jenis" name={["merchant", "merchant_type_id"]} rules={[{ required: true, message: "Kategori wajib diisi" }]}>
                <Select disabled={sending || loading}>
                    <Select.Option value={1}>Souvenir</Select.Option>
                    <Select.Option value={2}>Food</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item label="Kategori Toko" name={"merchant_selected_categories"} rules={[{ required: true, message: "Kategori wajib diisi" }]}>
                <SelectMerchantCategory merchantTypeId={selectedType} />
            </Form.Item>

            <Form.Item label="Identitas Pemilik" name={["merchant", "owner_identity_id"]} rules={[{ required: true, message: "Identitas Pemilik wajib diisi" }]}>
                <SelectIdentity disabled={sending || loading} />
            </Form.Item>

            <Form.Item label="Penanggung Jawab" name={["merchant", "pic_name"]} rules={[{ required: true, message: "Penanggung Jawab wajib diisi" }]}>
                <Input disabled={sending || loading} />
            </Form.Item>

            <Form.Item label="Nomor Telepon Penanggung Jawab" name={["merchant", "pic_mobile_number"]} rules={[{ required: true, message: "Nomor Telepon Penanggung Jawab wajib diisi" }]}>
                <Input disabled={sending || loading} />
            </Form.Item>

            <Form.Item label="Nomor WhatsApp" name={["merchant", "whatsapp_number"]} rules={[{ required: true, message: "Nomor WhatsApp wajib diisi" }]}>
                <Input disabled={sending || loading} />
            </Form.Item>

            <Form.Item label="Harga mulai dari" name={["merchant", "price_start_from"]} rules={[{ required: true, message: "Harga mulai dari wajib diisi" }]}>
                <Input disabled={sending || loading} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
                <Title style={{ margin: 0 }} level={4}>
                    Detail (Opsional)
                </Title>
            </Form.Item>

            <Form.Item getValueFromEvent={getFileURLValueFromEvent} label="Logo" name={["merchant", "logo"]} help="Disarankan menggunakan resolusi 640*640px dengan ukuran file kurang dari 2MB, pastikan elemen gambar tepat ditengah">
                {!loading ? (
                    <Upload headers={uploadHeaders} beforeUpload={beforeUpload} defaultFileList={defaultLogoFileList ? [defaultLogoFileList] : undefined} listType="picture-card" maxCount={1} action="https://api.bina-umkm.unmul.ac.id/api/upload">
                        <UploadOutlined />
                    </Upload>
                ) : null}
            </Form.Item>

            <Form.Item getValueFromEvent={getFileURLValueFromEvent} label="Banner" name={["merchant_details", "banner_link"]} help="Banner dapat digunakan untuk merepresntasikan isi Toko, Disarankan menggunakan resolusi 1200*600px dengan ukuran file kurang dari 2MB">
                {!loading ? (
                    <Upload headers={uploadHeaders} beforeUpload={beforeUpload} defaultFileList={defaultBannerFileList ? [defaultBannerFileList] : undefined} listType="picture-card" maxCount={1} action="https://api.bina-umkm.unmul.ac.id/api/upload">
                        <UploadOutlined />
                    </Upload>
                ) : null}
            </Form.Item>

            <Form.Item label="GoFood" name={["merchant_details", "gofood_link_id"]}>
                <Input addonBefore="https://gofood.link/u/" disabled={sending || loading} />
            </Form.Item>

            <Form.Item label="Tokopedia" name={["merchant_details", "tokopedia_link_id"]}>
                <Input addonBefore="https://tokopedia.com/" disabled={sending || loading} />
            </Form.Item>

            <Form.Item label="Google Business" name={["merchant_details", "google_business_link_id"]}>
                <Input disabled={sending || loading} />
            </Form.Item>

            <Form.Item label="Nomor NPWP" name={["merchant_details", "tax_number_id"]}>
                <Input disabled={sending || loading} />
            </Form.Item>

            <Form.Item label="Nomor Induk Berusaha (NIB)" name={["merchant_details", "business_registration_number"]}>
                <Input disabled={sending || loading} />
            </Form.Item>

            <Form.Item label="Nomor Penjamah Makanan" name={["merchant_details", "food_handler_number"]}>
                <Input disabled={sending || loading} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
                <Title style={{ margin: 0 }} level={4}>
                    Galeri
                </Title>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 12 }} getValueFromEvent={getValueFromEventGalery} name="merchant_galleries">
                {!loading ? (
                    <Upload headers={uploadHeaders} beforeUpload={beforeUpload} defaultFileList={defaultGalleriesFileList} listType="picture-card" maxCount={6} action="https://api.bina-umkm.unmul.ac.id/api/upload">
                        <UploadOutlined />
                    </Upload>
                ) : null}
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
                <Button disabled={sending || loading} type="primary" htmlType="submit">
                    {props.action === "create" ? "Tambah" : null}
                    {props.action === "update" ? "Perbaharui" : null}
                </Button>
            </Form.Item>
        </Form>
    );
};
