import React, { useEffect, useState } from "react";
import { Layout, Menu, Modal } from "antd";
import { DesktopOutlined, ShopOutlined, FileOutlined, UserSwitchOutlined, KeyOutlined, LogoutOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./AdminLayout.css";
import { useNavigate } from "react-router";
import Login from "../../screens/admin/Login";
import Transition from "../../screens/Transition";
import { userService } from "../../service";
import { notification } from "../../components";
import useCurrentUser from "../../hooks/useCurrentUser";
import { UserRole } from "../../types";

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const AdminLayout: React.FC = (props) => {
    const navigate = useNavigate();
    const { role, setCurrentUser } = useCurrentUser();

    const [loading, setLoading] = useState<boolean>(true);
    const [sending, setSending] = useState<boolean>(false);
    const [haveToken, setHaveToken] = useState<boolean>(false);

    const login = async (username: string, password: string) => {
        setSending(true);
        try {
            const resp = await userService.login(username, password);

            localStorage.setItem("token", resp.data.token);

            setTimeout(() => {
                setSending(false);
                authCheck();
            }, 1000);
        } catch (error) {
            setSending(false);
            notification.error("Masuk", error);
        }
    };

    const logout = () => {
        Modal.confirm({
            title: "Keluar dari aplikasi?",
            icon: <ExclamationCircleOutlined />,
            okText: "Keluar",
            cancelText: "Batal",
            okButtonProps: { disabled: sending },
            cancelButtonProps: { disabled: sending },
            onOk: async () => {
                setHaveToken(false);
                localStorage.removeItem("token");
            },
        });
    };

    const authCheck = () => {
        setLoading(true);

        const token = localStorage.getItem("token");

        if (token && token !== "undefined") {
            userService.getProfile()
                .then((resp) => {
                    setCurrentUser(resp.data)
                    setHaveToken(true)
                }).catch(() => {
                    setHaveToken(false)
                });
        };

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    useEffect(() => {
        authCheck();
        // eslint-disable-next-line
    }, []);
    
    if (loading) return <Transition />;

    if (!haveToken) return <Login login={login} sending={sending} />;

    return (
        <div id="components-layout-demo-top-side-2">
            <Layout style={{ minHeight: "100vh" }}>
                <Header className="header">
                    <div className="logo">
                        <img style={{ height: "100%" }} src={"/images/logo-unmul.png"} alt="Logo Universitas Mulawarman" />
                    </div>
                </Header>
                <Layout>
                    <Sider
                        theme="light"
                        collapsible
                        width={200}
                        className="site-layout-background"
                        style={{
                            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
                            zIndex: 99,
                        }}
                    >
                        <Menu theme="light" mode="inline" style={{ height: "100%", borderRight: 0 }}>
                            <Menu.Item key="1" icon={<DesktopOutlined />}>
                                Dasbor
                            </Menu.Item>
                            <SubMenu key="2" icon={<ShopOutlined />} title="Pedagang">
                                <Menu.Item key="21" onClick={() => navigate("/admin/merchants")}>
                                    Pedagang
                                </Menu.Item>
                                <Menu.Item key="22" onClick={() => navigate("/admin/merchants/waiting_approval")}>
                                    Butuh Persetujuan
                                </Menu.Item>
                            </SubMenu>
                            <Menu.Item key="3" onClick={() => navigate("/admin/news")} icon={<FileOutlined />}>
                                Berita
                            </Menu.Item>
                            {role === UserRole.ADMIN  ? (
                                <Menu.Item key="4" onClick={() => navigate("/admin/users")} icon={<UserSwitchOutlined />}>
                                    Pengguna
                                </Menu.Item>
                            ) : null}
                            {/* 
                                <Menu.Item key="5" onClick={() => navigate("/admin/identities")} icon={<ProfileOutlined />}>
                                    Identitas
                                </Menu.Item>
                            */}
                            <Menu.Item key="7" onClick={() => navigate("/admin/change-password")} icon={<KeyOutlined />}>
                                Ganti Kata Sandi
                            </Menu.Item>
                            <Menu.Item key="8" onClick={() => logout()} icon={<LogoutOutlined />}>
                                Keluar
                            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout>
                        <Content className="site-layout-background" style={{ marginLeft: 40, marginRight: 40, marginTop: 40 }}>
                            {props.children}
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </div>
    );
};

export default AdminLayout;
