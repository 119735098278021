import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { merchantService } from "../../service";
import { Merchant, MerchantCategory } from "../../types";
import { getInitials } from "./Home";

interface MerchantListParams {
    merchantType?: "souvenir" | "makanan";
}

const MerchantList: React.FC = () => {
    const navigate = useNavigate();
    const params = useParams() as MerchantListParams;
    let merchantTypeId: number | null = null;
    let title: string = "";
    let icon: string = "";
    if (params.merchantType === "souvenir") {
        merchantTypeId = 1;
        title = "Souvenir";
        icon = "/images/souvenir-heading-icon.png";
    }
    if (params.merchantType === "makanan") {
        merchantTypeId = 2;
        title = "Makanan";
        icon = "/images/food-heading-icon.png";
    }

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<Merchant[]>([]);
    const [categories, setCategories] = useState<MerchantCategory[]>([]);
    const [selectedCategoriesId, setSelectedCategoriesId] = useState<number[]>([]);
    const [search, setSearch] = useState<string>("");
    const [limit, setLimit] = useState<number>(30);

    const getMerchant = async () => {
        setLoading(true);
        try {
            const res = await merchantService.getMerchants({
                limit: limit,
                filters: {
                    merchant_name: search,
                    merchant_type_id: merchantTypeId,
                    merchant_status: "approved",
                    merchant_categories: selectedCategoriesId,
                },
            });

            setData(res.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleSelectedCategoriesId = (id: number) => {
        let updatedCategoriesId = [...selectedCategoriesId];
        if (selectedCategoriesId.includes(id)) {
            updatedCategoriesId = updatedCategoriesId.filter((catId) => catId !== id);
        } else {
            updatedCategoriesId.push(id);
        }

        setSelectedCategoriesId(updatedCategoriesId);
    };

    useEffect(() => {
        getMerchant();
        merchantService.getCategories(Number(merchantTypeId)).then((resp) => setCategories(resp.data));
        // eslint-disable-next-line
    }, [merchantTypeId, selectedCategoriesId, limit]);

    return (
        <div className="merchant-list-wrapper">
            <div className="merchant-list-header">
                <div className="merchant-list-header-left">
                    <img src={icon} alt={title} />
                    <h2>{title}</h2>
                </div>
            </div>
            <div className="merchant-list-categories-wrapper">
                {categories.map((c, key) => {
                    return (
                        <div onClick={() => handleSelectedCategoriesId(c.id)} className={`merchant-list-category ${selectedCategoriesId.includes(c.id) ? "merchant-list-category-active" : ""}`}>
                            <span>{c.name}</span>
                        </div>
                    );
                })}
            </div>
            <div className="merchant-list-search-wrapper">
                <div className="merchant-list-search">
                    <input onChange={(e) => setSearch(e.target.value)} type="text" />
                    <button onClick={() => getMerchant()}>Cari</button>
                </div>
            </div>
            <div className="merchant-list-items-wrapper">
                {loading ? (
                    <Skeleton />
                ) : (
                    data.map((m, k) => {
                        return (
                            <div onClick={() => navigate(`/pedagang/${params.merchantType}/${m.name}?uuid=${m.uuid}-${m.uuid}&&id=${m.id}`)} key={k} className="merchant-list-item">
                                <div className="merchant-list-item-img">{m.logo ? <img src={`https://api.bina-umkm.unmul.ac.id/${m.logo}`} alt={m.name} /> : <span>{getInitials(m.name)}</span>}</div>
                                <div className="merchant-list-title">
                                    <span>{m.name}</span>
                                </div>
                                <div className="merchant-list-price-start-from">
                                    <span>Mulai dari {m.price_start_from || 0}</span>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>

            <div className="list-load-more-wrapper" onClick={() => setLimit(limit + 10)}>
                <span>Muat lebih banyak</span>
            </div>
        </div>
    );
};

export default MerchantList;
