import { Button, PageHeader } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { NewsTable } from "../../../features";

const NewsList: React.FC = () => {
    const navigate = useNavigate();

    return (
        <PageHeader
            title="Daftar Berita"
            extra={
                <Button onClick={() => navigate("/admin/news/add")} type="primary">
                    Tambah Berita
                </Button>
            }
        >
            <NewsTable />
        </PageHeader>
    );
};

export default NewsList;
