import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { newsService } from "../../service";
import { EditorState, convertFromRaw } from "draft-js";
import { Editor, EditorProps } from "react-draft-wysiwyg";
import { NewsFind } from "../../types";

const NewsDetail: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const [editorState, setEditorState] = useState<EditorState>();
    const [news, setNews] = useState<NewsFind>();

    const getNews = async (id: number) => {
        try {
            const resp = await newsService.findNews(id);
            setNews(resp.data.news);
            setEditorState(() => EditorState.createWithContent(convertFromRaw(JSON.parse(resp.data.news.content))));
        } catch (error) {
            //
        }
    };

    useEffect(() => {
        if (searchParams.get("id")) {
            getNews(Number(searchParams.get("id")));
        }
        // eslint-disable-next-line
    }, []);

    const editorProps: EditorProps = {
        toolbarHidden: true,
        editorState: editorState,
        onEditorStateChange: setEditorState,
        readOnly: true,
    };

    return (
        <div className="news-detail-wrapper">
            <div style={{ maxWidth: 720 }}>
                <span>{news?.created_at}</span>
                <h2 style={{ fontSize: 36, lineHeight: 1.2 }}>{news?.title}</h2>
                {editorState ? <Editor {...editorProps} /> : null}
            </div>
        </div>
    );
};

export default NewsDetail;
