import { APIResponse, User, UserCreateRequest, UserLoginResp, UserUpdateRequest } from "../types";
import api from "./api";

export const getUsers = (): Promise<APIResponse<User[]>> => api.get("/api/users");

export const getUser = (id: number): Promise<APIResponse<User>> => api.get(`/api/user?id=${id}`);

export const getProfile = (): Promise<APIResponse<User>> => api.get(`/api/user/profile`);

export const createUser = (data: UserCreateRequest) => api.post("/api/user", data);

export const updateUser = (data: UserUpdateRequest) => api.put("/api/user", data);

export const login = (username: string, password: string): Promise<APIResponse<UserLoginResp>> => api.post("/api/login", { username, password });
