import { useContext } from "react";
import CurrentUserCtx from "../context/CurrentUser";

const useCurrentUser = () => {
    const currentUserCtx = useContext(CurrentUserCtx);

    const currentUser = currentUserCtx.currentUser

    const setCurrentUser = currentUserCtx.setCurrentUser

    const role = currentUser.role;

    const token = localStorage.getItem('token')

    return { currentUser, setCurrentUser, role, token };
};

export default useCurrentUser;
