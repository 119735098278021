import { APIGetRequest, APIResponse, NewsCreateRequest, NewsFindResponse, NewsList, NewsUpdateRequest } from "../types";
import api from "./api";

export const getNewsTags = (): Promise<APIResponse<any[]>> => api.get("/api/news-tags");

export const addTag = (newTagName: string): Promise<APIResponse<any>> => api.post("/api/news-tag", { name: newTagName });

export const getNews = (data?: APIGetRequest<any>): Promise<APIResponse<NewsList[]>> => api.post("/api/news/get", data);

export const addNews = (data: NewsCreateRequest): Promise<APIResponse<NewsFindResponse>> => api.post("/api/news", data);

export const updateNews = (data: NewsUpdateRequest): Promise<APIResponse<NewsFindResponse>> => api.put("/api/news", data);

export const findNews = (newsId: number): Promise<APIResponse<NewsFindResponse>> => api.get(`/api/news/find?id=${newsId}`);
