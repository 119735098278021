import { APIResponse, FileUploadResp } from "../types";
import api from "./api";

export const upload = (file: File): Promise<APIResponse<FileUploadResp>> => {
    var formData = new FormData();
    formData.append("file", file);
    return api.post("/api/upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};
