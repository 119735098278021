import React from "react";

const Transition: React.FC = () => {
    return (
        <div style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <img height="300" src={"/images/logo-unmul.png"} alt="Logo Universitas Mulawarman" />
        </div>
    );
};

export default Transition;
