import React, { useState } from "react";
import "./MainLayout.css";
import { Link } from "react-router-dom";

const MainLayout: React.FC = (props) => {
    const [active, setActive] = useState<"beranda" | "souvenir" | "makanan" | "berita" | "tentang">("beranda");

    return (
        <div className="main-layout-wrapper">
            <div className="main-layout">
                <header className="header-wrapper">
                    <div className="header-title-wrapper">
                        <img height="50" src="/images/kedai-reka.png" alt="Kedai Reka" />
                    </div>
                    <div>
                        <img height="50" src="/images/kemendikbud.png" alt="Kementerian Pendidikan dan Kebudayaan" />
                        <img height="50" src="/images/unmul.png" alt="Universitas Mulawarman Samarinda" />
                        <img height="50" src="/images/kota-samarinda.png" alt="Kota Samarinda" />
                        <img height="75" src="/images/gojek.png" alt="Gojek" />
                    </div>
                </header>
                <nav className="nav">
                    <Link onClick={() => setActive("beranda")} to="/" className={active === "beranda" ? "nav-link-active" : "nav-link"}>
                        <img src={active === "beranda" ? "/images/nav/beranda-green.png" : "/images/nav/beranda-white.png"} alt="Beranda" />
                        Beranda
                    </Link>
                    <Link onClick={() => setActive("souvenir")} to="/pedagang/souvenir" className={active === "souvenir" ? "nav-link-active" : "nav-link"}>
                        <img src={active === "souvenir" ? "/images/nav/souvenir-green.png" : "/images/nav/souvenir-white.png"} alt="Souvenir" />
                        Souvenir
                    </Link>
                    <Link onClick={() => setActive("makanan")} to="/pedagang/makanan" className={active === "makanan" ? "nav-link-active" : "nav-link"}>
                        <img src={active === "makanan" ? "/images/nav/food-green.png" : "/images/nav/food-white.png"} alt="Makanan" />
                        Makanan
                    </Link>
                    <Link onClick={() => setActive("berita")} to="/berita" className={active === "berita" ? "nav-link-active" : "nav-link"}>
                        <img src={active === "berita" ? "/images/nav/news-green.png" : "/images/nav/news-white.png"} alt="Kanal Berita" />
                        Kanal Berita
                    </Link>
                    <Link onClick={() => setActive("tentang")} to="/tentang" className={active === "tentang" ? "nav-link-active" : "nav-link"}>
                        <img src={active === "tentang" ? "/images/nav/about-green.png" : "/images/nav/about-white.png"} alt="Tentang" />
                        Tentang
                    </Link>
                </nav>
                <article>{props.children}</article>
            </div>

            <footer className="footer-wrapper">
                <div className="footer-content-wrapper">
                    <div className="footer-item-about">
                        <img height="50" src="/images/kedai-reka.png" alt="Kedai Reka" />
                        <p style={{ marginTop: 20, maxWidth: 500 }}>Kedaireka adalah solusi terkini dalam mewujudkan kemudahan sinergi kontribusi perguruan tinggi dengan komersialisasi industri untuk kemajuan bangsa Indonesia, yang sejalan dengan visi Kampus Merdeka Kemendikbud RI.</p>
                    </div>
                    <div className="footer-item">
                        <h3>Navigasi</h3>
                        <ul>
                            <li>
                                <Link to="/">Beranda</Link>
                            </li>
                            <li>
                                <Link to="/pedagang/souvenir">Souvenir</Link>
                            </li>
                            <li>
                                <Link to="/pedagang/makanan">Makanan</Link>
                            </li>
                            <li>
                                <Link to="/berita">Kanal Berita</Link>
                            </li>
                            <li>
                                <Link to="/tentang">Tentang</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-item">
                        <h3>Tautan</h3>
                        <ul>
                            <li>
                                <Link to="/admin">Masuk</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="copyright-wrapper">
                    <span>© Bina UMKM Universitas Mulawarman 2021</span>
                    <span>Hak Cipta Dilindungi Undang-Undang</span>
                </div>
            </footer>
        </div>
    );
};

export default MainLayout;
