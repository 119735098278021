import { Button, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { newsService } from "../../service";
import { NewsList } from "../../types";

export const NewsTable: React.FC = () => {
    const navigate = useNavigate();
    const [news, setNews] = useState<NewsList[]>([]);

    const getNews = async () => {
        try {
            const resp = await newsService.getNews();
            console.log("resp", resp);
            setNews(resp.data);
        } catch (error) {
            console.log("error UserTable.getUsers", error);
        }
    };

    useEffect(() => {
        getNews();
    }, []);

    const columns: ColumnsType<NewsList> = [
        {
            title: "Judul",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Tags",
            key: "tag",
            render: (_, data) => {
                return data.selected_news_tag.map((tag, index) => <Tag key={index}>{tag.news_tag.name}</Tag>);
            },
        },
        {
            title: "Tindakan",
            dataIndex: "id",
            key: "action",
            width: "100px",
            render: (_, user) => (
                <Space>
                    <Button onClick={() => navigate(`/admin/news/view/${user.id}`)} disabled type="link">
                        Lihat
                    </Button>
                    <Button onClick={() => navigate(`/admin/news/edit/${user.id}`)} type="link">
                        Ubah
                    </Button>
                    <Button onClick={() => navigate(`/admin/news/edit/${user.id}`)} disabled type="link" danger>
                        Hapus
                    </Button>
                </Space>
            ),
        },
    ];

    return <Table dataSource={news} columns={columns} />;
};
