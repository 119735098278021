import { PageHeader } from "antd";
import React from "react";
import { useParams } from "react-router";
import { UserForm } from "../../../features";

interface UserEditParams {
    userId?: string;
}

const UserEdit: React.FC = () => {
    const params = useParams() as UserEditParams;
    return (
        <PageHeader title="Ubah Pengguna">
            <div style={{ marginTop: 40 }}>
                <UserForm action="update" userId={params.userId ? Number(params.userId) : -1} />
            </div>
        </PageHeader>
    );
};

export default UserEdit;
