import { Select, Button, Divider, SelectProps, Modal, notification } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { identityService } from "../../service";
import { Identity } from "../../types";
import { IdentityForm, IdentityFormImperativeHandle } from "../../features";

export const SelectIdentity = (props: SelectProps<any>) => {
    const identityFormRef = useRef<IdentityFormImperativeHandle>();
    const [identities, setIdentities] = useState<Identity[]>([]);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const getIdentities = async () => {
        setLoading(true);
        try {
            const resp = await identityService.getIdentities();
            setIdentities(resp.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("error SelectIdentity.getIdentities", error);
        }
    };

    const onActionSuccess = () => {
        notification.success({ message: "Identitas", description: "Identitas berhasil dibuat." });
        setShowModal(false);
        setLoading(true);
        setTimeout(() => {
            getIdentities();
        }, 1000);
    };

    const onActionFail = (error: any) => {
        notification.error({ message: "Identitas", description: String(error) });
    };

    useEffect(() => {
        getIdentities();
    }, []);

    return (
        <React.Fragment>
            <Select
                {...props}
                disabled={props.disabled || loading}
                loading={props.loading || loading}
                showSearch
                filterOption={(input, option) => {
                    const opt: string = String(option?.children);
                    const val: string = input;
                    return opt.includes(val);
                }}
                dropdownRender={(menu) => (
                    <div>
                        {menu}
                        <Divider style={{ margin: "4px 0" }} />
                        <Button onClick={() => setShowModal(true)} icon={<PlusOutlined />} type="link">
                            Tambah Identitas
                        </Button>
                    </div>
                )}
            >
                {identities.map((identity, key) => {
                    return <Select.Option key={key} value={identity.id}>{`${identity.full_name} - ${identity.id_number}`}</Select.Option>;
                })}
            </Select>

            <Modal title="Tambah Identitas" visible={showModal} okText="Tambah" onOk={() => identityFormRef.current?.create()} onCancel={() => setShowModal(false)}>
                <IdentityForm ref={identityFormRef} action="create" userId={-1} onActionSuccess={onActionSuccess} onActionFail={onActionFail} />
            </Modal>
        </React.Fragment>
    );
};
