import { Button, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { userService } from "../../service";
import { User } from "../../types";

export const UserTable: React.FC = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState<User[]>([]);

    const getUsers = async () => {
        try {
            const resp = await userService.getUsers();
            console.log("resp", resp);
            setUsers(resp.data);
        } catch (error) {
            console.log("error UserTable.getUsers", error);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const columns: ColumnsType<User> = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            render: (_, user) => <span style={{ textTransform: "capitalize" }}>{user.role.replace("_", " ")}</span>,
        },
        {
            title: "Tindakan",
            dataIndex: "id",
            key: "action",
            width: "100px",
            render: (_, user) => (
                <Space>
                    <Button onClick={() => navigate(`/admin/user/view/${user.id}`)} disabled type="link">
                        Lihat
                    </Button>
                    <Button onClick={() => navigate(`/admin/user/edit/${user.id}`)} type="link">
                        Ubah
                    </Button>
                    <Button onClick={() => navigate(`/admin/user/edit/${user.id}`)} disabled type="link" danger>
                        Hapus
                    </Button>
                </Space>
            ),
        },
    ];

    return <Table dataSource={users} columns={columns} />;
};
