import { notification } from "antd";

export const error = (title: string, err: any) => {
    console.log("err", err);
    console.log("err.response", err.response);
    console.log("err.message", err.message);
    let description = String(err);

    if (err && err.response && err.response.data && err.response.data.message) {
        description = err.response.data.message;
        if (description === "The given data was invalid.") {
            if (err.response.data.errors) {
                const errors = err.response.data.errors;
                let errorsString = "";
                // eslint-disable-next-line
                for (const [key, value] of Object.entries(errors)) {
                    if (Array.isArray(value)) {
                        for (let index = 0; index < value.length; index++) {
                            const theErr = value[index];
                            errorsString = errorsString + " " + theErr;
                        }
                    }
                }

                if (errorsString) description = errorsString;
            }
        }
    } else if (err && err.message) {
        description = err.message;
    }

    return notification.error({ message: title, description: description });
};

export const success = (title: string, msg: string) => {
    return notification.success({ message: title, description: msg });
};
