import { Button, Input, Form, Select, Upload, message, Divider } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../layouts/admin/AdminLayout.css";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { UploadChangeParam, RcFile } from "antd/lib/upload";
import { APIResponse, FileUploadResp, NewsCreateRequest } from "../../types";
import { HttpRequestHeader, UploadFile } from "antd/lib/upload/interface";
import { fileService, newsService } from "../../service";
import { notification } from "../../components";
import useCurrentUser from "../../hooks/useCurrentUser";

type FileListType = UploadFile<APIResponse<FileUploadResp>>;

export interface NewsFormImperative {
    create: () => void;
    update: () => void;
}
interface NewsFormProps {
    newsId: string;
    action: "create" | "edit";
    onActionSuccess: () => void;
    onActionFail: (error: any) => void;
}

export const NewsForm = forwardRef((props: NewsFormProps, ref) => {
    const [newsForm] = Form.useForm<NewsCreateRequest>();
    const { token } = useCurrentUser()

    const uploadHeaders: HttpRequestHeader = {
        Authorization: token || ''
    }

    const [newTag, setNewTag] = useState<string>("");
    const [newsTags, setNewsTags] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [defaultMainPictureList, setDefaultMainPictureList] = useState<FileListType>();
    const [editorState, setEditorState] = useState<EditorState>();

    useImperativeHandle(
        ref,
        (): NewsFormImperative => ({
            update() {
                newsForm.submit();
            },
            create() {
                newsForm.submit();
            },
        })
    );

    const initiate = async () => {
        setLoading(true);
        try {
            if (props.action === "edit" && props.newsId && props.newsId !== "-1") {
                const resp = await newsService.findNews(Number(props.newsId));
                newsForm.setFieldsValue({
                    news: resp.data.news,
                    news_selected_tags: resp.data.news_selected_tags,
                });

                if (resp.data.news.main_picture) {
                    setDefaultMainPictureList({
                        uid: resp.data.news.id.toString(),
                        name: "Logo",
                        status: "done",
                        response: {
                            data: {
                                file: resp.data.news.main_picture,
                            },
                            message: "OK",
                        },
                        url: `https://api.bina-umkm.unmul.ac.id/${resp.data.news.main_picture}`,
                    });
                }
                setEditorState(() => EditorState.createWithContent(convertFromRaw(JSON.parse(resp.data.news.content))));
            } else {
                setEditorState(() => EditorState.createEmpty());
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            notification.error("Berita", error);
        }
    };

    const save = async (values: NewsCreateRequest) => {
        try {
            if (props.action === "create") {
                await newsService.addNews(values);
            }

            if (props.action === "edit") {
                await newsService.updateNews({
                    news: {
                        ...values.news,
                        id: Number(props.newsId),
                    },
                    news_selected_tags: values.news_selected_tags,
                });
            }

            props.onActionSuccess();
        } catch (error) {
            props.onActionFail(error);
        }
    };

    const uploadCallback = async (file: File): Promise<any> => {
        try {
            const resp = await fileService.upload(file);
            return Promise.resolve({
                data: {
                    link: `https://api.bina-umkm.unmul.ac.id/${resp.data.file}`,
                },
            });
        } catch (error) {
            notification.error("Berita", error);
            return Promise.reject("error");
        }
    };

    const getNewsTags = () => newsService.getNewsTags().then((resp) => setNewsTags(resp.data));

    const addNewTag = (newTagName: string) =>
        newsService
            .addTag(newTagName)
            .then(() => {
                getNewsTags();
                setNewTag("");
                notification.success("Tag", "Berhasil menambah tag.");
            })
            .catch((error) => notification.error("Tag", error));

    useEffect(() => {
        initiate();
        getNewsTags();
        // eslint-disable-next-line
    }, []);

    const editorProps = {
        toolbar: {
            image: {
                previewImage: true,
                uploadCallback: uploadCallback,
                alt: { present: true, mandatory: false },
                defaultSize: {
                    height: "auto",
                    width: "300",
                },
            },
        },
        editorState: editorState,
        onEditorStateChange: setEditorState,
        toolbarClassName: "react-draft-wysiwyg-toolbar",
        wrapperClassName: "react-draft-wysiwyg-wrapper",
        editorClassName: "react-draft-wysiwyg-editor",
        placeholder: "Isi Berita",
    };

    const getEditorValueFromEvent = () => (editorState ? JSON.stringify(convertToRaw(editorState.getCurrentContent())) : "");

    const getFileURLValueFromEvent = (uploadRes: UploadChangeParam<UploadFile<APIResponse<FileUploadResp>>>) => {
        return uploadRes.file.response?.data.file;
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("Hanya dapat mengunggah file dengan extensi JPG atau PNG.");
            return Upload.LIST_IGNORE;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Hanya dapat mengunggah file dibawah 2MB.");
            return Upload.LIST_IGNORE;
        }
    };

    return (
        <Form style={{ marginTop: 20 }} layout="horizontal" form={newsForm} onFinish={save} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
            <Form.Item getValueFromEvent={getFileURLValueFromEvent} label="Gambar Utama" name={["news", "main_picture"]} help="Banner dapat digunakan untuk merepresntasikan isi berita, Disarankan menggunakan resolusi 1200*600px dengan ukuran file kurang dari 2MB">
                {!loading ? (
                    <Upload headers={uploadHeaders} beforeUpload={beforeUpload} defaultFileList={defaultMainPictureList ? [defaultMainPictureList] : undefined} listType="picture-card" maxCount={1} action="https://api.bina-umkm.unmul.ac.id/api/upload">
                        <UploadOutlined />
                    </Upload>
                ) : null}
            </Form.Item>

            <Form.Item label="Judul" name={["news", "title"]} rules={[{ required: true, message: "Judul wajib diisi." }]}>
                <Input size="large" placeholder="Judul" />
            </Form.Item>

            <Form.Item label="Tags" name="news_selected_tags">
                <Select
                    size="large"
                    placeholder="Pilih tag (bisa lebih dari satu)"
                    mode="multiple"
                    dropdownRender={(menu) => (
                        <div>
                            {menu}
                            <Divider style={{ margin: "4px 0" }} />
                            <div style={{ display: "flex", flexWrap: "nowrap", padding: 8, columnGap: 5 }}>
                                <Input placeholder="Masukkan tag baru" onChange={(e) => setNewTag(e.target.value)} style={{ flex: "auto" }} />
                                <Button onClick={() => addNewTag(newTag)} icon={<PlusOutlined />} type="link">
                                    Tambah Tag
                                </Button>
                            </div>
                        </div>
                    )}
                >
                    {newsTags.map((tag, key) => (
                        <Select.Option key={key} value={tag.id}>
                            {tag.name}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>

            {editorState ? (
                <Form.Item getValueFromEvent={getEditorValueFromEvent} label="Isi Berita" name={["news", "content"]} rules={[{ required: true, message: "Isi berita wajib diisi." }]}>
                    <Editor {...editorProps} />
                </Form.Item>
            ) : null}
        </Form>
    );
});
