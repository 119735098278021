import { PageHeader } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import { notification } from "../../../components";
import { MerchantForm } from "../../../features";

const MerchantCreate: React.FC = () => {
    const navigate = useNavigate();

    const onActionSuccess = () => {
        navigate("/admin/merchants");
        notification.success("Toko/Warung", "Berhasil menambahkan toko/warung.");
    };

    const onActionFail = (error: any) => {
        notification.error("Toko/Warung", error);
    };

    return (
        <PageHeader title="Tambah Toko/Warung">
            <div style={{ marginTop: 40 }}>
                <MerchantForm action="create" onActionSuccess={onActionSuccess} onActionFail={onActionFail} merchantId={-1} />
            </div>
        </PageHeader>
    );
};

export default MerchantCreate;
