import { Form, Input, Button } from "antd";

interface LoginProps {
    sending: boolean;
    login: (username: string, password: string) => void;
}

interface LoginForm {
    username: string;
    password: string;
}

const Login: React.FC<LoginProps> = (props) => {
    const onFinish = (values: LoginForm) => {
        props.login(values.username, values.password);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: "75vh" }}>
            <img height="150" src={"/images/logo-unmul.png"} alt="Logo Universitas Mulawarman" />
            <div style={{ width: 300, marginTop: 50 }}>
                <Form layout="vertical" name="login" onFinish={onFinish} autoComplete="off">
                    <Form.Item label="Username" name="username" rules={[{ required: true, message: "Please input your username!" }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Password" name="password" rules={[{ required: true, message: "Please input your password!" }]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item>
                        <Button disabled={props.sending} type="primary" htmlType="submit" block>
                            Masuk
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;
