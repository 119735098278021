import { PageHeader } from "antd";
import React from "react";
import { UserForm } from "../../../features";

const UserCreate: React.FC = () => {
    return (
        <PageHeader title="Tambah Pengguna">
            <div style={{ marginTop: 40 }}>
                <UserForm action="create" userId={-1} />
            </div>
        </PageHeader>
    );
};

export default UserCreate;
